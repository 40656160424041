<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="formData.semester_id" type="number" step="1"
                                :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="taken_at" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('date_took_the_course')">
                            <b-form-input type="date" v-model="formData.taken_at" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('type')">
                        <multi-selectbox :multiple="false" v-model="courseType"
                            :options="[{ text: $t('this_university_course'), value: 1 }, { text: $t('external_course'), value: 2 }]" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" v-if="courseType == 1">
                    <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="formData.course_id" v-on:courseSelected="courseSelected"
                                :valueObject="courseObject" :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="courseType == 2">
                    <ValidationProvider name="country_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="formData.country_id" :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="courseType == 2">
                    <ValidationProvider name="university_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('university')">
                            <university-selectbox v-model="formData.university_id" :countryId="formData.country_id"
                                :isCountryRequired="true" :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="courseType == 2">
                    <ValidationProvider name="university_course_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course')">
                            <university-course-auto-complete v-model="formData.university_course_id"
                                :university-id="formData.university_id" :country="formData.country_id"
                                v-on:courseSelected="universityCourseSelected" :valueObject="courseObject"
                                :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="credit" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('credit')">
                            <b-form-input v-model="formData.credit" type="number" step="1" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="ects_credit" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('ects_credit')">
                            <b-form-input v-model="formData.ects_credit" type="number" step="0.5" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="grading_system_id" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('grading_system')">
                            <grade-system-selectbox :grading_system_id="formData.grading_system_id"
                                v-model="formData.grading_system_id" :validateError="errors[0]" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="letter_grade" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('letter_grade')">
                            <grade-selectbox :grading_system_id="formData.grading_system_id" value-type="grade"
                                v-model="formData.letter_grade" :validateError="errors[0]" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('course_type')">
                            <parameter-selectbox code="student_course_types" v-model="formData.type"
                                :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="section_id" :rules="tSections.length > 0 ? 'required' : ''"
                        v-if="section_show" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('section')">
                            <multi-selectbox :options="tSections" :multiple="false" v-model="formData.section_id"
                                :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="section_show && pSections.length > 0">
                    <ValidationProvider name="lab_section_id" rules="required"
                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('lab_section')">
                            <multi-selectbox :options="pSections" :multiple="false" v-model="formData.lab_section_id"
                                :validateError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" />
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import GradeSelectbox from '@/components/interactive-fields/GradeSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';

// Services
import StudentCourseService from '@/services/StudentCourseService'
import SectionService from '@/services/SectionService'
import SemesterDateService from '@/services/SemesterDateService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import qs from "qs";
import UniversityCourseAutoComplete
    from "@/components/interactive-fields/UniversityCourseAutoComplete";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox";
import GradeSystemSelectbox from "@/components/interactive-fields/GradeSystemSelectbox";

import Base from '@/plugins/Base';
import form from "../../../../boardDecisions/pages/Form.vue";

export default {
    computed: {
        form() {
            return form
        }
    },
    components: {
        GradeSystemSelectbox,
        UniversitySelectbox,
        CountrySelectbox,
        MultiSelectbox,
        CourseAutoComplete,
        GradeSelectbox,
        ParameterSelectbox,
        SemestersSelectbox,
        UniversityCourseAutoComplete,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        slotId: {
            type: Number
        },
        formId: {
            type: Number
        }
    },
    data() {
        return {
            courseObject: {
                value: null,
                text: null
            },
            formData: {},
            formLoading: false,
            tSections: [],
            pSections: [],
            courseType: 1,
            section_show: true,
            taken_at: {}
        }
    },
    watch: {
        'formData.semester_id': {
            async handler(value) {
                if (value) {
                    this.getSections();
                }
                if (this.active_semester == value) {
                    this.formData.taken_at = this.currentDate()
                    return
                }

                const config = {
                    params: {
                        filter: {
                            type: "education",
                            semester_id: value
                        },
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
                };
                const response = await SemesterDateService.options(config)
                if (response?.data?.data.length > 0) {
                    this.taken_at.start_date = response.data.data[0].start_date
                    this.taken_at.end_date = response.data.data[0].end_date

                    this.formData.taken_at = this.$moment(this.taken_at.start_date, "YYYY-MM-DD").format("YYYY-MM-DD");
                }
            }
        },
        courseType: {
            handler(val) {
                if (val == 2) {
                    this.section_show = false;
                }
                else {
                    this.section_show = true;
                }
            },
            immediate: true
        },
        'formData.section_id':{
            handler(value){
                let section=[]
                section= this.tSections.filter(item=>{
                    return item.value===value
                })
                if (section.length){
                    this.formData.section=section[0].text


                }
            }
        }
    },
    created() {
        this.get(this.formId)
    },
    mounted() {
        this.$refs.formModalValidate.reset()
        this.active_semester = Base.LocalStorage.get('A/student/programs/*/slots/edit_semesters')?.value?.active;
    },
    methods: {
        async get(id) {
            await StudentCourseService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.courseObject = {
                        value: data.course_id,
                        text: this.getLocaleText(data, 'course_name')
                    }
                    if (data.university_course_id > 0) {
                        this.courseType = 2;
                        this.courseObject.value = data.university_course_id;
                    }
                    this.formData = data;
                    this.getSections();
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                // if(!this.pSections.length){
                //     this.formData.section_id=null;
                // }
                if(!this.tSections.length){
                    this.formData.lab_section_id=null;
                }
                StudentCourseService.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        courseSelected(course) {
            this.formData.university_course_id = null;
            this.formData.course_id = course.item.id;
            this.formData.credit = course.item.credit;
            this.formData.ects_credit = course.item.ects_credit;
            this.formData.grading_system_id = course.item.grading_system_id;
            this.getSections();
        },

        universityCourseSelected(course) {
            this.formData.course_id = null;
            this.formData.section_id = null;
            this.formData.lab_section_id = null;
            this.tSections = [];
            this.pSections = [];
            this.formData.university_course_id = course.item.id;
            this.formData.credit = course.item.credit;
            this.formData.ects_credit = course.item.ects_credit;
            this.formData.grading_system_id = null;
        },
        getSections() {
            const config = {
                params: {
                    filter: {
                        semester_id: this.formData.semester_id,
                        course_id: this.formData.course_id,
                    },
                    limit: -1,
                    sort: 'section'
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };
            SectionService.options(config).then(response => {
                this.tSections = response.data.data.filter(function (item) {
                    return item.type == 1;
                }).map(function (item) {
                    return { value: item.id, text: item.section };
                });


                this.pSections = response.data.data.filter(function (item) {
                    return item.type == 2;
                }).map(function (item) {
                    return { value: item.id, text: item.section };
                });
            });
        }
    }
}
</script>

